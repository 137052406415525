
export function getConfiguration() {
    
  return fetch("/config.json", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json())
    .catch(
      error => {
        throw Error('Error while loading local configuration!');
      }
    );
}
