import React, { FC, useContext } from 'react';
import { AppContext } from '../App/App.tsx';

export const useLoading = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};

export const GlobalLoading: FC = props => {
  const { isLoading } = useLoading();

  const renderLoading = () => (
    <>
      <style>{`
            .spinner-container {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              background-color: rgba(255, 255, 255, 0.9);
              bottom: 0;
              z-index: 10000;
            }
            
            .spinner-wrapper {
              top: 50%;
              position: relative;
              margin-top: -1.5rem;
            }
            
            .center {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              height: 100vh;
            }
            
            .circle{
              border-radius: 50%;
              box-shadow: 0px 0px 1px 1px #0000001a;
            }
            
            .pulse {
              animation: pulse-animation 2s infinite;
            }
            
            @keyframes pulse-animation {
              0% {
                box-shadow: 0 0 0 0px rgba(0,0,0, 0.2);
              }
              100% {
                box-shadow: 0 0 0 20px rgba(0,0,0,0);
              }
            }
        `}</style>
      <div className="spinner-container">
        <div className="d-flex justify-content-center spinner-wrapper">
          <img src={process.env.PUBLIC_URL + '/logo.png'} className="circle pulse" alt="Cliqeur" width="32" height="32" />
        </div>
      </div>
    </>
  );

  if (!isLoading) return null;
  return <React.Fragment>{renderLoading()}</React.Fragment>;
};
