import React from "react";

export interface PrivacyProps {
  prop?: string;
}

export function Privacy({ prop = "default value" }: PrivacyProps) {
  return (
    <React.Fragment>
      <div className="pricing-header mx-auto text-center">
        <h1 className="display-5 fw-semibold text-body-emphasis text-center mb-4" style={{letterSpacing: '-1px'}}>Privacy policy</h1>
        
      </div>
      <div className="container col-xxl-8 bg-body-tertiary rounded-3 p-3">
        <div id="outputPage">
          <div data-exp="simple2" className="outputVersion1">
            <div>
              <p>
                The site <b>cliqeur.com</b> is owned and operated by Prominent Bytes Inc.
                Prominent Bytes Inc is the data controller and can be contacted at: <br />
                <br />
                201-1300 Cornwall Rd,
                <br />
                Oakville, ON L6J 7W5, Canada
                <br />
                info@cliqeur.com
              </p>
              <p>
                <strong>
                  <u>Purpose</u>
                </strong>
                <br />
                The purpose of this privacy policy (this "Privacy Policy") is to
                inform users of our Site of the following:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>The personal data we will collect;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Use of collected data;</span>
                  <br />
                </li>
                <li value="3">
                  <span>Who has access to the data collected;</span>
                  <br />
                </li>
                <li value="4">
                  <span>The rights of Site users; and</span>
                  <br />
                </li>
                <li value="5">
                  <span>The Site's cookie policy.</span>
                  <br />
                </li>
              </ol>
              <p>
                This Privacy Policy applies in addition to the terms and
                conditions of our Site.
              </p>
              <div>
                <p>
                  <strong>
                    <u>GDPR</u>
                  </strong>
                  <br />
                  For users in the European Union, we adhere to the Regulation
                  (EU) 2016/679 of the European Parliament and of the Council of
                  27 April 2016, known as the General Data Protection Regulation
                  (the "GDPR"). For users in the United Kingdom, we adhere to the
                  GDPR as enshrined in the Data Protection Act 2018.
                  <br />
                  <br />
                  We have not appointed a Data Protection Officer as we do not
                  fall within the categories of controllers and processors
                  required to appoint a Data Protection Officer under Article 37
                  of the GDPR.
                </p>
              </div>
              <div>
                <p>
                  <strong>
                    <u>Consent</u>
                  </strong>
                  <br />
                  By using our Site users agree that they consent to:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>The conditions set out in this Privacy Policy.</span>
                    <br />
                  </li>
                </ol>
                <p>
                  When the legal basis for us processing your personal data is
                  that you have provided your consent to that processing, you may
                  withdraw your consent at any time. If you withdraw your consent,
                  it will not make processing which we completed before you
                  withdrew your consent unlawful.
                </p>
                <p>You can withdraw your consent by: Customer service.</p>
              </div>
              <div>
                <p>
                  <strong>
                    <u>Legal Basis for Processing</u>
                  </strong>
                  <br />
                  We collect and process personal data about users in the EU only
                  when we have a legal basis for doing so under Article 6 of the
                  GDPR. <br />
                  <br />
                  We rely on the following legal bases to collect and process the
                  personal data of users in the EU:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>
                      Users have provided their consent to the processing of their
                      data for one or more specific purposes; and
                    </span>
                    <br />
                  </li>
                  <li value="2">
                    <span>
                      Processing of user personal data is necessary for us to
                      take, at the request of a user, steps before entering a
                      contract or for the performance of a contract to which a
                      user is a party. If a user does not provide the the personal
                      data necessary to perform a contract the consequences are as
                      follows: The user can not proceed with buying a software
                      subscription online.
                    </span>
                    <br />
                  </li>
                </ol>
              </div>
              <p>
                <strong>
                  <u>Personal Data We Collect</u>
                </strong>
                <br />
                We only collect data that helps us achieve the purpose set out in
                this Privacy Policy. We will not collect any additional data
                beyond the data listed below without notifying you first.
                <br />
              </p>
              <p>
                <u>Data Collected Automatically</u>
                <br />
                When you visit and use our Site, we may automatically collect and
                store the following information:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>IP address;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Location;</span>
                  <br />
                </li>
                <li value="3">
                  <span>Hardware and software details;</span>
                  <br />
                </li>
                <li value="4">
                  <span>Address;</span>
                  <br />
                </li>
                <li value="5">
                  <span>Payment Card; and</span>
                  <br />
                </li>
                <li value="6">
                  <span>Email.</span>
                  <br />
                </li>
              </ol>
              <p>
                <u>Data Collected in a Non-Automatic Way</u>
                <br />
                We may also collect the following data when you perform certain
                functions on our Site:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>First and last name;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Date of birth;</span>
                  <br />
                </li>
                <li value="3">
                  <span>Email address;</span>
                  <br />
                </li>
                <li value="4">
                  <span>Phone number;</span>
                  <br />
                </li>
                <li value="5">
                  <span>Address;</span>
                  <br />
                </li>
                <li value="6">
                  <span>Payment information; and</span>
                  <br />
                </li>
                <li value="7">
                  <span>Company Name.</span>
                  <br />
                </li>
              </ol>
              <p>This data may be collected using the following methods:</p>
              <ol start={1}>
                <li value="1">
                  <span>Creating an account; and</span>
                  <br />
                </li>
                <li value="2">
                  <span>Subscription.</span>
                  <br />
                </li>
              </ol>
              <p>
                <strong>
                  <u>How We Use Personal Data</u>
                </strong>
                <br />
                Data collected on our Site will only be used for the purposes
                specified in this Privacy Policy or indicated on the relevant
                pages of our Site. We will not use your data beyond what we
                disclose in this Privacy Policy.
                <br />
                <br />
                The data we collect automatically is used for the following
                purposes:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>Statistics;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Generate Invoice; and</span>
                  <br />
                </li>
                <li value="3">
                  <span>Send promotions emails to customers.</span>
                  <br />
                </li>
              </ol>
              <p>
                The data we collect when the user performs certain functions may
                be used for the following purposes:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>Display in customer profile;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Invoices; and</span>
                  <br />
                </li>
                <li value="3">
                  <span>Email Promotions.</span>
                  <br />
                </li>
              </ol>
              <p>
                <strong>
                  <u>Who We Share Personal Data With</u>
                </strong>
                <br />
                <u>Employees</u>
                <br />
                We may disclose user data to any member of our organization who
                reasonably needs access to user data to achieve the purposes set
                out in this Privacy Policy.
              </p>
              <div>
                <p>
                  <u>Third Parties</u>
                  <br />
                  We may share user data with the following third parties:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>Payment card gateways.</span>
                    <br />
                  </li>
                </ol>
                <p>We may share the following user data with third parties:</p>
                <ol start={1}>
                  <li value="1">
                    <span>Payment information;</span>
                    <br />
                  </li>
                  <li value="2">
                    <span>Customer profile; and</span>
                    <br />
                  </li>
                  <li value="3">
                    <span>Customer subscription.</span>
                    <br />
                  </li>
                </ol>
                <p>
                  We may share user data with third parties for the following
                  purposes:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>Process payment, generate invoice.</span>
                    <br />
                  </li>
                </ol>
                <p>
                  Third parties will not be able to access user data beyond what
                  is reasonably necessary to achieve the given purpose.
                </p>
              </div>
              <div>
                <p>
                  <u>Other Disclosures</u>
                  <br />
                  We will not sell or share your data with other third parties,
                  except in the following cases:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>If the law requires it;</span>
                    <br />
                  </li>
                  <li value="2">
                    <span>If it is required for any legal proceeding;</span>
                    <br />
                  </li>
                  <li value="3">
                    <span>To prove or protect our legal rights; and</span>
                    <br />
                  </li>
                  <li value="4">
                    <span>
                      To buyers or potential buyers of this company in the event
                      that we seek to sell the company.
                    </span>
                    <br />
                  </li>
                </ol>
                <p>
                  If you follow hyperlinks from our Site to another site, please
                  note that we are not responsible for and have no control over
                  their privacy policies and practices.
                </p>
              </div>
              <p>
                <strong>
                  <u>How Long We Store Personal Data</u>
                </strong>
                <br />
                User data will be stored until the purpose the data was collected
                for has been achieved.
                <br />
                <br />
                You will be notified if your data is kept for longer than this
                period.
              </p>
              <p>
                <strong>
                  <u>How We Protect Your Personal Data</u>
                </strong>
                <br />
                In order to protect your security, we use the strongest available
                browser encryption and store all of our data on servers in secure
                facilities. All data is only accessible to our employees. Our
                employees are bound by strict confidentiality agreements and a
                breach of this agreement would result in the employee's
                termination.
                <br />
                <br />
                While we take all reasonable precautions to ensure that user data
                is secure and that users are protected, there always remains the
                risk of harm. The Internet as a whole can be insecure at times and
                therefore we are unable to guarantee the security of user data
                beyond what is reasonably practical.
              </p>
              <div>
                <p>
                  <strong>
                    <u>International Data Transfers</u>
                  </strong>
                  <br />
                  We transfer user personal data to the following countries:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>Canada; and</span>
                    <br />
                  </li>
                  <li value="2">
                    <span>USA.</span>
                    <br />
                  </li>
                </ol>
                <p>
                  When we transfer user personal data we will protect that data as
                  described in this Privacy Policy and comply with applicable
                  legal requirements for transferring personal data
                  internationally.
                </p>
                <p>
                  If you are located in the United Kingdom or the European Union,
                  we will only transfer your personal data if:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>
                      The country your personal data is being transferred to has
                      been deemed to have adequate data protection by the European
                      Commission or, if you are in the United Kingdom, by the
                      United Kingdom adequacy regulations; or
                    </span>
                    <br />
                  </li>
                  <li value="2">
                    <span>
                      We have implemented appropriate safeguards in respect of the
                      transfer. For example, the recipient is a party to binding
                      corporate rules, or we have entered into standard EU or
                      United Kingdom data protection contractual clauses with the
                      recipient.
                    </span>
                    <br />
                  </li>
                </ol>
              </div>
              <p>
                <strong>
                  <u>Your Rights as a User</u>
                </strong>
                <br />
                Under the GDPR, you have the following rights:
              </p>
              <ol start={1}>
                <li value="1">
                  <span>Right to be informed;</span>
                  <br />
                </li>
                <li value="2">
                  <span>Right of access;</span>
                  <br />
                </li>
                <li value="3">
                  <span>Right to rectification;</span>
                  <br />
                </li>
                <li value="4">
                  <span>Right to erasure;</span>
                  <br />
                </li>
                <li value="5">
                  <span>Right to restrict processing;</span>
                  <br />
                </li>
                <li value="6">
                  <span>Right to data portability; and</span>
                  <br />
                </li>
                <li value="7">
                  <span>Right to object.</span>
                  <br />
                </li>
              </ol>
              {/* <p>
                <strong>
                  <u>
                    How to Access, Modify, Delete, or Challenge the Data Collected
                  </u>
                </strong>
                <br />
                If you would like to know if we have collected your personal data,
                how we have used your personal data, if we have disclosed your
                personal data and to who we disclosed your personal data, if you
                would like your data to be deleted or modified in any way, or if
                you would like to exercise any of your other rights under the
                GDPR, please contact our privacy officer here:
                <br />
                <br />
                __________
                <br />
                ________________________________________
                <br />
                ________________________________________
                <br />
                ________________________________________
              </p> */}
              <div>
                <p>
                  <strong>
                    <u>Do Not Track Notice</u>
                  </strong>
                  <br />
                  Do Not Track ("DNT") is a privacy preference that you can set in
                  certain web browsers. We do not track the users of our Site over
                  time and across third party websites and therefore do not
                  respond to browser-initiated DNT signals. We are not responsible
                  for and cannot guarantee how any third parties who interact with
                  our Site and your data will respond to DNT signals.
                </p>
              </div>
              <div>
                <p>
                  <strong>
                    <u>How to Opt-Out of Data Collection, Use or Disclosure</u>
                  </strong>
                  <br />
                  In addition to the method(s) described in the{" "}
                  <em>
                    How to Access, Modify, Delete, or Challenge the Data Collected
                  </em>{" "}
                  section, we provide the following specific opt-out methods for
                  the forms of collection, use, or disclosure of your personal
                  data:
                </p>
                <ol start={1}>
                  <li value="1">
                    <span>
                      You can opt-out of the use of your personal data for
                      marketing emails. You can opt-out by clicking "unsubscribe"
                    </span>
                    <br />
                  </li>
                </ol>
              </div>
              <div>
                <p>
                  <strong>
                    <u>Cookie Policy</u>
                  </strong>
                  <br />A cookie is a small file, stored on a user's hard drive by
                  a website. Its purpose is to collect data relating to the user's
                  browsing habits. You can choose to be notified each time a
                  cookie is transmitted. You can also choose to disable cookies
                  entirely in your internet browser, but this may decrease the
                  quality of your user experience.
                </p>
                <p>We use the following types of cookies on our Site:</p>
                <ol start={1}>
                  <li value="1">
                    <u>
                      <span>Functional cookies</span>
                    </u>
                    <br />
                    Functional cookies are used to remember the selections you
                    make on our Site so that your selections are saved for your
                    next visits; and
                    <br />
                  </li>
                  <li value="2">
                    <u>
                      <span>Analytical cookies</span>
                    </u>
                    <br />
                    Analytical cookies allow us to improve the design and
                    functionality of our Site by collecting data on how you access
                    our Site, for example data on the content you access, how long
                    you stay on our Site, etc.
                    <br />
                  </li>
                </ol>
              </div>
              <p>
                <strong>
                  <u>Modifications</u>
                </strong>
                <br />
                This Privacy Policy may be amended from time to time in order to
                maintain compliance with the law and to reflect any changes to our
                data collection process. When we amend this Privacy Policy we will
                update the "Effective Date" at the top of this Privacy Policy. We
                recommend that our users periodically review our Privacy Policy to
                ensure that they are notified of any updates. If necessary, we may
                notify users by email of changes to this Privacy Policy.
              </p>
              <p>
                <strong>
                  <u>Complaints</u>
                </strong>
                <br />
                If you have any complaints about how we process your personal
                data, please contact us through the contact methods listed in the{" "}
                <em>Contact</em> section so that we can, where
                possible, resolve the issue.
              </p>
              {/* <p>
                <strong>
                  <u>Contact Information</u>
                </strong>
                <br />
                If you have any questions, concerns or complaints, you can contact
                our privacy officer, __________, at:
                <br />
                <br />
                ________________________________________
                <br />
                ________________________________________
                <br />
                ________________________________________
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
