import { IFeature } from "../models/IFeature";
import { IPricingTable } from "../models/IPricingTable";
import { IRelease } from "../models/IRelease";

export function getPlans(config: { [key: string]: string }) {
    
  return fetch(config['api'] + "/product/plans", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json() )
    .then((data) => data as IPricingTable)
    .catch(
      error => {
        throw Error('Error while loading plans!');
      }
    );
}

export function getFeatures(config: { [key: string]: string }) {
    
  return fetch(config['api'] + "/product/features", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json() )
    .then((data) => data as IFeature[])
    .catch(
      error => {
        throw Error('Error while loading features!');
      }
    );
}

export function getLatestRelease(config: { [key: string]: string }) {
    
  return fetch(config['api'] + "/product/version/latest", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json() )
    .then((data) => data as IRelease)
    .catch(
      error => {
        throw Error('Error while getting latest release!');
      }
    );
}

export function download(release: IRelease, config: { [key: string]: string }) {
    
  return fetch(`${config['api']}/product/download/${release.version}`, {
      method: "GET",
      headers: {
        'x-cliqr-v': config['version'],
        'x-cliqr-b': release.version,
        'x-cliqr-p': 'browser',
        'x-cliqr-os': navigator.userAgent,
        'x-cliqr-language': navigator.language,
        //'Access-Control-Allow-Credentials': 'true',
      }
    })
    .then((response) => response.blob() )
    .catch(
      error => {
        throw Error('Error while getting latest release!');
      }
    );
}
