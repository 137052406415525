import React, { useState, useEffect, useCallback } from 'react';
import { getConfiguration } from '../../Configuration.tsx';
import { useLoading } from '../GlobalLoading/GlobalLoading.tsx';
import { Link } from 'react-router-dom';
import { download, getLatestRelease } from '../../datasource/ProductDatasource.tsx';
import { Button } from 'react-bootstrap';
import { IRelease } from '../../models/IRelease.ts';

export interface ProductProps {
  prop?: string;
}

export function Product({prop = 'default value'}: ProductProps) {
  const { setIsLoading } = useLoading();
  const [loaded, setLoaded] = useState(false);
  const [config, setConfig] = useState({});
  const [latestRelease, setLatestRelease] = useState({} as IRelease);

 

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    getConfiguration()
    .then((config) => {
      if (mounted) {
        setConfig(config);

        return getLatestRelease(config);
      }
      else{
        return null;
      }
      
    })
    .then((release) => {
      if (release) {
        setLatestRelease(release);
      }
      setIsLoading(false);  
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
    });
    
    return () => {
      mounted = false
    };

  }, [] );


  const downloadRelease = async () => {
    
    try {

      setIsLoading(true);

      download(latestRelease, config)
      .then((blob) => {
        
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${latestRelease.fileName}`;
        a.click();
        URL.revokeObjectURL(objectUrl);

        
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  return (
  <React.Fragment>
    <div className="px-3 pt-2 my-5 mt-5 text-center border-bottom">
      <div className="overflow-hidden" style={{maxHeight: '40vh'}}>
        <div className="container px-4">
          <img src={process.env.PUBLIC_URL + '/cliqeur_screenshot_1.png'} className="img-fluid border rounded-2 shadow-lg mb-4" alt="Cliqeur" width="700" height="500" loading="lazy"/>
        </div>
      </div>
      <h1 className="display-1 mt-2 fw-bold text-body-emphasis" style={{letterSpacing: '-3px'}}><span className="text-secondary">try</span> cliqeur <span className="text-secondary fw-light">beta</span></h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">A simple application for automating web pages testing.</p>
        <div className="d-grid d-sm-flex justify-content-sm-center mb-5">
          <div className="d-inline-center mb-1">
            <Button 
              onClick={downloadRelease}
              className="d-inline-center align-items-center btn btn-secondary btn-lg px-4 rounded-pill">
              Download
              <i className="fa fa-windows mx-2"></i>
            </Button>
          </div>
        </div>
      </div>
      
    </div>
    <div className="container col-xxl-12 px-4 py-md-2 text-center text-lg-start">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-12 col-lg-7 mt-0">
          <img src={process.env.PUBLIC_URL + '/cliqeur_screenshot_2.png'} className="d-block mx-lg-auto img-fluid border rounded-1 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
        </div>
        <div className="col-lg-5">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Test Stories</h1>
          <p className="lead">Cliqeur offers you an intuitive interface to automate your web testings.</p>
        </div>
      </div>
    </div>
    <div className="container bg-body-tertiary rounded-3">
      <div className="container px-4 py-2 col-xxl-12 text-center text-lg-start">
        <div className="row align-items-center g-5 py-5">
          <div className="col-12 col-lg-7">
            <img src={process.env.PUBLIC_URL + '/cliqeur_screenshot_3.png'} className="d-block mx-lg-auto img-fluid border rounded-1 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
          </div>
          <div className="col-lg-5">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Embedded Recorder</h1>
            <p className="lead">With an embedded recorder, you can record web pages interactions.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-5 text-center text-lg-start">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-0">
        <div className="col-12 col-lg-7">
          <img src={process.env.PUBLIC_URL + '/cliqeur_screenshot_4.png'} className="d-block mx-lg-auto img-fluid border rounded-1 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
        </div>
        <div className="col-lg-5">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Test Pages</h1>
          <p className="lead">Cliqeur offers you features to test and take screenshots of your web pages for all kind of resolutions.</p>
        </div>
      </div>
    </div>
    <div className="px-4 py-5 my-5 text-center bg-body-tertiary rounded-3">
      <h1 className="text-body-emphasis">Try It For Free</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Try Cliqeur and simplify your web testings.</p>
        <div className="gap-2 d-sm-flex justify-content-sm-center">
          <Button 
            onClick={downloadRelease}
            className="d-inline-center align-items-center btn btn-primary btn-lg px-4 rounded-pill">
            Download
            <i className="fa fa-windows mx-2"></i>
          </Button>
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}
