import React, { useState, useEffect } from 'react';
import { getConfiguration } from '../../Configuration.tsx';
import Turnstile from "react-turnstile";
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useLoading } from '../GlobalLoading/GlobalLoading.tsx';

export interface ContactProps {
  prop?: string;
}

export function Contact({ prop = "default value" }: ContactProps) {
  
  const { setIsLoading } = useLoading();
  
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const [config, setConfig] = useState({});

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [captchaToken, setToken] = useState("");
  

  useEffect(() => {
    setIsLoading(true);

    let mounted = true;
    getConfiguration()
    .then((config) => {
      if (mounted) {
        setConfig(config);
        setLoaded(true);
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
    });
    return () => {
      mounted = false
    };
  }, []);


  const handleSubmit = (event) => {
    setFailed(false);
    setCompleted(false);
    setValidated(false);
    setIsLoading(true);

    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setFailed(true);
      setIsLoading(false);
    }
    
    setValidated(true);

    if (form.checkValidity() === true) {
      send();
    }
  };

  const send = async () => {
    
    setIsLoading(true);
    const formData = new URLSearchParams();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
    formData.append('captchaToken', captchaToken);

    try {
      const response = await fetch(config["api"]+"/contact", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });
  
      if (!response.ok) {
        const data = await response.json();

        setError(data["error"]);
        throw new Error('Network response was not ok');
      }
  
      setCompleted(true);
      setIsLoading(false);

    } catch (error) {
      console.error('Error:', error);
      setFailed(true);
      setIsLoading(false);
    }
  };

  const renderForm = () => (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="g-3 mb-3">
          { failed === true && 
            <Form.Group as={Col} md="12" controlId="name">
              <Alert variant="danger" className='mb-0' dismissible>
                <Alert.Heading className='mb-1'>You got an error!</Alert.Heading>
                <p className='mb-0'>
                  {error}
                </p>
              </Alert>
            </Form.Group>
          }
          <Form.Group as={Col} md="12" controlId="name">
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Control
              required
              type="text"
              placeholder="Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Name field is required!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="g-3 mb-3">
          <Form.Group as={Col} md="12" controlId="email">
            {/* <Form.Label>Email</Form.Label> */}
            <Form.Control
              required
              type="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Email field is required!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="g-3 mb-3">
          <Form.Group as={Col} md="12" controlId="message">
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Control
              required
              as="textarea"
              placeholder="Message"
              value={message}
              rows={6}
              onChange={e => setMessage(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Message field is required!
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            required
            label="Agree to terms and conditions"
            feedback="You must agree before submitting."
            feedbackType="invalid"
          />
        </Form.Group>
        {loaded && 
          <Turnstile
            sitekey={config["key"]}
            action='contact'
            onVerify={setToken}
          />
        }
        <div className="gap-2 d-sm-flex mt-2">
          <button className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill" type="submit">
            Send
            <i className="fa fa-send mx-2"></i>
          </button>
        </div>
      </Form>
    </>
  );

  const rendeSuccessMessage = () => (
    <>
      <Alert variant="success" className='mb-0'>
        <Alert.Heading>Thank you for messaging us!</Alert.Heading>
        <p>
          Thank you for contacting and showing interest for Cliqeur. We will process your request and get back to you as soon as possible.
        </p>
        <div className="d-flex justify-content-end">
          <Button onClick={() => window.location.reload()} variant="outline-success">
            Close
          </Button>
        </div>
      </Alert>
    </>
  );


  return (
    
      <React.Fragment>
        <div className="container row justify-content-center">
          <h1 className="display-5 fw-semibold text-body-emphasis text-center mb-4" style={{ letterSpacing: "-1px" }}>Contact us</h1>
          {/* <div className="col-xxl-6">
            <div className="bg-body-tertiary rounded-3 p-3">
              <p>
                Cliqeur
                <br />
                <b>Prominent Bytes, Inc.</b>
                <br />
                201-1300 Cornwall Rd,
                <br />
                Oakville 
                <br />
                ON, L6J 7W5
                <br />
                Canada
                <br />
                info@cliqeur.com
              </p>
            </div>
          </div> */}
          <div className="col-xxl-6">
            <div className="bg-body-tertiary rounded-3 p-3">
              {completed ? rendeSuccessMessage() : renderForm()}
            </div>
          </div>
        </div>
      </React.Fragment>
  );
}
