import React, { useState, useEffect, useCallback } from 'react';
import { getConfiguration } from '../../Configuration.tsx';
import { useLoading } from '../GlobalLoading/GlobalLoading.tsx';
import { download, getLatestRelease } from '../../datasource/ProductDatasource.tsx';
import { Button } from 'react-bootstrap';
import { IRelease } from '../../models/IRelease.ts';

export interface DownloadProps {
  prop?: string;
}

export function Download({prop = 'default value'}: DownloadProps) {
  
  const { setIsLoading } = useLoading();
  const [config, setConfig] = useState({});
  const [latestRelease, setLatestRelease] = useState({} as IRelease);

  useEffect(() => {
    let mounted = true;
    
    const fetchData = async () => {
      
      setIsLoading(true);

      try {
        const conf = await getConfiguration();
        if (mounted) {
          setConfig(conf);
        }

        const release = await getLatestRelease(conf);
        if (release) {
          setLatestRelease(release);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    }
    
    fetchData();
    
    return () => {
      mounted = false
    };

  }, [] );


  const downloadRelease = async () => {
    
    try {

      setIsLoading(true);

      download(latestRelease, config)
      .then((blob) => {
        
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${latestRelease.fileName}`;
        a.click();
        URL.revokeObjectURL(objectUrl);

        
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  
  
  return (
    <React.Fragment>
      <div className="pricing-header mx-auto text-center">
        <h1 className="display-5 fw-semibold text-body-emphasis text-center mb-2" style={{letterSpacing: '-1px'}}>
          Latest release
        </h1>
      </div>
      <div className="col-xxl-12">
        {latestRelease != null && latestRelease !== undefined &&
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-2 text-center">
            <span className="text-dark fw-light">
              <small>
                {new Date(latestRelease.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
              </small>
            </span>
            <br />
            <span className="text-dark fw-normal"><strong>{latestRelease.version} </strong> 
              {latestRelease?.stage?.toLowerCase() }
            </span>
          </p>
          <div className="d-grid d-flex justify-content-center mb-5">
            <div className="d-inline-center mb-1">
              <Button 
                onClick={downloadRelease}
                className="d-inline-center align-items-center btn btn-secondary btn-lg px-4 rounded-pill">
                Download
                <i className="fa fa-windows mx-2"></i>
              </Button>
            </div>
          </div>
        </div>
        }
        <div>
          <div className="row mb-0">
            <div className="col-12">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th style={{width:'30%'}} >
                      <div className="text">
                        <div>
                          <p>&nbsp;&nbsp; &nbsp;</p>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="text">
                        <div>
                          <p><b>Requirement</b></p>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className="text">
                        <div>
                          Processor
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>
                          Intel® processor; 2.5GHz or faster processor
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text">
                        <div>
                          Operating system
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>
                          Windows 11 (64 bit), Windows 10 (32 bit and 64 bit)
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text">
                        <div>
                          RAM
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>
                          4GB of RAM
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text">
                        <div>
                          Hard disk space
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>
                          1Go of available hard-disk space
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text">
                        <div>
                          Monitor resolution
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>
                          1024x768 screen resolution
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
