import React, { useState, useEffect } from 'react';
import { getConfiguration } from '../../Configuration.tsx';
import { Link} from 'react-router-dom';
import { getPlans, getFeatures } from '../../datasource/ProductDatasource.tsx';
import { IPricingTable } from '../../models/IPricingTable.tsx';
import { useLoading } from '../GlobalLoading/GlobalLoading.tsx';
import { IFeature } from '../../models/IFeature.ts';

export interface PricingProps {
  prop?: string;
}

export function Pricing({ prop = "default value" }: PricingProps) {

  const [ config, setConfig] = useState({});
  const [ plans, setPlans] = useState<IPricingTable>({table:[]});
  const [ features, setFeatures] = useState<IFeature[]>([]);
  const { setIsLoading } = useLoading();

  useEffect(() => {
    
    let mounted = true;
    
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const conf = await getConfiguration();
        if (mounted) {
          setConfig(conf);
        }
  
        const plans = await getPlans(conf);
        if (mounted) {
          setPlans(plans);
        }

        const features = await getFeatures(conf);
        if (mounted) {
          setFeatures(features);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };
  
    fetchData();

    
    
    return () => {
      mounted = false
    };

  }, []);

  return (
    <React.Fragment>
      <div className="pricing-header mx-auto text-center">
        <h1 className="display-5 fw-semibold text-body-emphasis text-center mb-4" style={{letterSpacing: '-1px'}}>Pricing</h1>
        
      </div>
      <div className="container col-xxl-8">
        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
          {plans.table.map((plan, index) => (
            <div className="col" key={index}>
              <div className="card mb-3 rounded-3 shadow-sm ">
                <div className="py-3">
                  <h4 className="my-0 fw-bold">{ plan.productName }</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                  ${ plan.priceUnitAmount/100 }<small className="text-body-secondary fw-light">/mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <Link className="w-100 btn btn-lg btn-outline-primary" to={config['portal']+'/subscription'}>Sign up</Link>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-xxl-12">
        <h2 className="display-6 text-center mb-4">Compare plans</h2>
        <div className="table-responsive bg-body-tertiary rounded-3 p-3">
          <table className="table text-center">
            <thead>
              <tr>
                <th style={{ width: "34%" }}></th>
                <th style={{ width: "22%" }}>Free</th>
                <th style={{ width: "22%" }}>Pro</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature, index) => (
              <tr key={index}>
                <th scope="row" className="text-start">
                  {feature.feature}
                </th>
                <td>
                  {typeof feature.options['Free'] === 'boolean' ? (
                    feature.options['Free'] ? (
                      <i className="fa fa-check-circle text-success" />
                    ) : (
                      <span className="bi">
                        <i className='fa fa-close' />
                      </span>
                    )
                  ) : (
                    feature.options['Free']
                  )}
                </td>
                <td>
                  {typeof feature.options['Pro'] === 'boolean' ? (
                    feature.options['Pro'] ? (
                      <i className="fa fa-check-circle text-success" />
                    ) : (
                      <span className="bi">
                        <i className='fa fa-close' />
                      </span>
                    )
                  ) : (
                    feature.options['Pro']
                  )}
                </td>
              </tr>
              ))}
              
              {/* <tr>
              <th scope="row" className="text-start">Google Chrome Driver</th>
              <td></td>
              <td><svg className="bi" width="24" height="24">
                  <use xlinkHref="#check" /></svg></td>
            </tr>
            <tr>
              <th scope="row" className="text-start">Mozilla Firefox Driver</th>
              <td></td>
              <td><svg className="bi" width="24" height="24">
                  <use xlinkHref="#check" /></svg></td>
            </tr> */}
              {/* <tr>
              <th scope="row" className="text-start">Reporting</th>
              <td></td>
              <td><svg className="bi" width="24" height="24">
                  <use xlinkHref="#check" /></svg></td>
            </tr> 
              <tr>
                <th scope="row" className="text-start">
                 Reporting
                </th>
                <td>
                  <span className="bi">
                    <i className='fa fa-close' />
                  </span>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#check" />
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Customer Support
                </th>
                <td>
                  <span className="bi">
                    <i className='fa fa-close' />
                  </span>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#check" />
                  </svg>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
